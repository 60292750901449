import { computed, Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";

import {
  ProjectService,
  SiteService,
  DefectService,
  ClientUserService,
  CatchErrorService,
  AlertService,
  HashService,
} from "../services";

import type { IDefectContractorAssignment } from "../models";

import {
  Project,
  List,
  Defect,
  IDefectStatus,
  HashDataId,
  ProjectPrimaryKeyId,
  ListPrimaryKeyId,
  DefectPrimaryKeyId,
  PrimaryKeyId,
  DefectReviewStatus,
} from "../models";

import SortHelper from "../helpers/sorting.helper";

import { environment } from "../../environments/environment";

/** Internet connectivity service */
@Injectable({
  providedIn: "root",
})
export class ClientProjectProvider {
  /** Whether current user is a projet owner */
  readonly isOwner: boolean = false;
  /** For comparing against comments/other */
  uid: number | undefined;
  /** Company name */
  company = signal<string>("");
  projectImage = signal<string>("");

  // PROJECT SCREEN INFORMATION
  /** Project Id number */
  projectHashId = signal<HashDataId | "">("");
  /** Project object */
  project = signal<Project.Project | undefined>(undefined);
  /** Array of original sites */
  lists: Project.ListView[] = [];
  /** TODO: project contractors */
  contractors: Project.Contractor[] = [];

  // PROJECT UI CONTROLS
  /** UI Control for things pending get project */
  loadingProject = signal<boolean>(false);
  loadingList = signal<boolean>(false);
  loadingDefect = signal<boolean>(true);

  // LIST SCREEN INFO
  /** Project Id number */
  listHashId = signal<HashDataId | "">("");
  /** IListView screen view object */
  list = signal<List.List | undefined>(undefined);
  /** Original array of defects for list */
  listDefects: List.DefectView[] = [];

  // DEFECT SCREEN INFO
  /** Defect object to show on page */
  private _defect: Defect.Defect | undefined;
  /** Defect photos array */
  originalDefectPhotos: Defect.Photo[] = [];
  /** Defect photos array */
  defectPhotos: Defect.Photo[] = [];
  /** Holds the old id when switching and updating UI */
  oldDefectStatus: IDefectStatus | undefined;
  /** Contractor Assignments - Only used for populating the sub-component */
  originalDefectContractors: IDefectContractorAssignment[] = [];

  // DEFECT UI CONTROLS
  /** controls the side panel or modal */
  showDefectInPanel: boolean = false;
  /** controls the side panel or modal */
  showDefectInModal: boolean = false;
  /** Contractor Assignments - Used for the multi-select control */
  defectContractorIds: PrimaryKeyId[] = [];
  /** Controls the gallery popup in list.html */
  showDefectGallery: boolean = false;
  /** Sets which image to open in the gallery popup in list.html */
  activeGalleryIndex: number = 0;

  /** @ignore */
  constructor(
    private ps: ProjectService,
    private ss: SiteService,
    private ds: DefectService,
    private us: ClientUserService,
    private cx: CatchErrorService,
    private as: AlertService,
    private router: Router,
    private hash: HashService
  ) {}

  /** Project Id number */
  projectId = computed<ProjectPrimaryKeyId | undefined>(() => {
    const projectHashId = this.projectHashId();
    if (projectHashId) {
      return this.hash.decode<ProjectPrimaryKeyId>(projectHashId);
    }
    return undefined;
  });

  /** Project Id number */
  listId = computed<ListPrimaryKeyId | undefined>(() => {
    const listHashId = this.listHashId();
    if (listHashId) {
      return this.hash.decode<ListPrimaryKeyId>(listHashId);
    }
    return undefined;
  });

  get defect(): Defect.Defect | undefined {
    return this._defect;
  }

  /** Set defect - clears related items if undefined */
  set defect(o: Defect.Defect | undefined) {
    if (o === undefined) {
      // resetting defect, reset associated stuff
      this.oldDefectStatus = undefined;
      this.showDefectInModal = false;
      this.showDefectInPanel = false;
      this.defectPhotos = [];
      this.originalDefectContractors = [];
    }
    this._defect = o;
  }

  /**
   * Clear / Wipe any data stored in the Project Provider
   */
  destroy(): void {
    if (!environment.production) {
      console.log("Reset client project data");
    }
    this.projectHashId.update(() => "");
    this.project.update(() => undefined);
    this.lists = [];
    this.contractors = [];
    this.loadingProject.update(() => true);

    this.destroyList();
    this.destroyDefect();
  }
  /**
   * Clear list screen data
   */
  destroyList(): void {
    this.list.update(() => undefined);
  }
  /**
   * Clear the cached defect
   */
  destroyDefect(): void {
    this.defect = undefined;
  }
  /**
   * Close the currently selected defect
   */
  closeDefect(): void {
    this.destroyDefect();
    this.showDefectInModal = false;
    this.showDefectInPanel = false;
  }
  /**
   * Retrieves project data and relevant information for the user.
   *
   * @param {boolean} keepChildIds - Whether to keep child ids or not.
   * @return {Promise<{
   *    project: Project.Project;
   *    lists: Project.ListView[];
   *    contractors: Project.Contractor[];
   *    projectTeam: Project.TeamMember[];
   *    reports: Project.Report[];
   *    isOwner: boolean;
   * }>} - A promise that resolves with the project data.
   * @throws {string} - Access denied if no subscription found or rejection error if failed to get project data.
   */
  async getProject(keepChildIds?: boolean): Promise<boolean> {
    if (!environment.production) {
      console.log("getClientProject()");
    }
    this.loadingProject.update(() => true);

    if (this.uid === undefined) {
      const clientIds = await this.us.getUserIds();

      if (clientIds.tenantId && clientIds.userId) {
        this.uid = clientIds.userId;
      } else {
        return Promise.reject("Error, please logout and then try again.");
      }
    }

    try {
      const projectId = this.projectId();

      if (projectId !== undefined) {
        if (keepChildIds !== true) {
          this.destroyList();
          this.destroyDefect();
        }

        const { project, lists, contractors, company, projectImage } =
          await this.ps.getClientProjectPageData(projectId);

        this.company.update(() => company);
        this.projectImage.update(() => projectImage);
        this.project.update(() => project);
        this.contractors = contractors;
        this.lists = SortHelper.defaultListsSort(lists, project.type);
        return true;
      }
    } catch (ex) {
      const error = JSON.stringify(ex);
      if (error.includes("User is inactive or does not have permission")) {
        this.cx.logWarning(error);
      } else {
        this.cx.handle(ex, true);
      }
      return Promise.reject(ex);
    } finally {
      this.loadingProject.update(() => false);
    }
    return Promise.reject("Unknown error loading project data");
  }
  /**
   * Get Site & defect data
   */
  async getProjectList(keepChildIds?: boolean): Promise<boolean> {
    if (this.uid === undefined) {
      const clientIds = await this.us.getUserIds();

      if (clientIds.tenantId && clientIds.userId) {
        this.uid = clientIds.userId;
      } else {
        return Promise.reject("Error, please logout and then try again.");
      }
    }

    const projectId = this.projectId();
    const listId = this.listId();

    if (!this.loadingList() && projectId && listId) {
      this.loadingList.update(() => true);
      // check if project is loaded, if not, do it first
      if (this.project() === undefined) {
        await this.getProject(true);
      }

      if (keepChildIds !== true) {
        this.destroyDefect();
      }

      try {
        const { site, defects } = await this.ss.getClientSitePageData(
          listId,
          projectId
        );

        this.listDefects = defects;
        this.listDefects.sort(SortHelper.sortDefects);
        this.list.update(() => site);

        this.loadingList.update(() => false);
        return true;
      } catch (ex) {
        this.cx.handle(ex, true);
        this.loadingList.update(() => false);
        this.router.navigate(["/client", this.projectHashId()]);
        return false;
      }
    } else {
      this.loadingList.update(() => false);
      this.router.navigate(["/client", this.projectHashId()]);
      return false;
    }
  }
  /**
   * Get the defect
   */
  async getDefect(defectId?: DefectPrimaryKeyId): Promise<void> {
    try {
      const projectId = this.projectId();

      if (!projectId) return;

      if (defectId) {
        this.loadingDefect.update(() => true);

        if (this.uid === undefined) {
          const clientIds = await this.us.getUserIds();

          if (clientIds.tenantId && clientIds.userId) {
            this.uid = clientIds.userId;
          } else {
            return Promise.reject("Error, please logout and then try again.");
          }
        }

        const { defect, photos } = await this.ds.getClientDefect(
          defectId,
          projectId
        );

        this.defect = defect;
        this.oldDefectStatus = defect.status;
        this.oldDefectStatus = this.oldDefectStatus;
        this.defectPhotos = [...photos];
        this.originalDefectPhotos = [...photos];
        if (!environment.production) {
          console.log("GOT DEFECT", this.defect, this.defectPhotos);
        }
      } else {
        // new
        this.defect = {
          cloudId: -1 as DefectPrimaryKeyId,
          defectNumberString: "NEW",
          defectNumber: 0,
          area: "",
          element: "",
          issue: "",
          comments: "",
          created: new Date(),
          completed: undefined,
          dueDate: undefined,
          status: 0,
          priority: 1,
          lastModified: 0,
          reviewStatusId: DefectReviewStatus.NOT_REVIEWED,
          reviewStatus: "NOT REVIEWED",
        };
        this.defectPhotos = [];
        this.originalDefectPhotos = [];
        this.originalDefectContractors = [];
        // Sets default contractors for a new defect
        this.defectContractorIds = [
          ...this.contractors
            .filter((o) => o.defaultAssignment)
            .map((o) => o.contractorCloudId),
        ];
      }
    } catch (ex) {
      const exMessage = JSON.stringify(ex);
      if (exMessage.includes("Unable to find defect in database")) {
        this.as.showAlert({
          title: "Error",
          message:
            "Defect not found, it may have been deleted. Try refreshing the page.",
        });
        this.cx.logWarning(ex);
      } else if (ex && !exMessage.includes("#401")) {
        await this.cx.handle(
          ex,
          true,
          `Error retrieving defect from server: ${
            exMessage ?? ex ?? "Unknown error"
          }`
        );
      } else {
        await this.cx.handle(ex, true);
      }
      // Refresh list data
      this.showDefectInPanel = false;
      this.showDefectInModal = false;
      await this.getProjectList(true);
    } finally {
      this.loadingDefect.update(() => false);
    }
  }
}
